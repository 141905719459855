import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import SEO from "../components/seo"
import { PhoneCarousel } from "../blocks/PhoneCarousel"
import { PlainSelector } from "../blocks/PlainSelector"
import { SolidBoi } from "../blocks/SolidBoi"
import { PageForm, Page } from "../templates/page"

const FeaturesPage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data?.dataJson, PageForm)

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      bottomCta
    >
      {page?.blocks &&
        page?.blocks.map(({ _template, ...data }) => {
          switch (_template) {
            case "SolidBoiBlock":
              // @ts-ignore
              return <SolidBoi {...data} />
            case "PhoneCarouselBlock":
              // @ts-ignore
              return <PhoneCarousel key={data?.id} {...data} />
            default:
              return true
          }
        })}
    </Page>
  )
}

export const query = graphql`
  query featuresQuery {
    dataJson(fileRelativePath: { eq: "/src/data/features.json" }) {
      title
      description
      blocks {
        _template
        title
        linkTitle
        linkUrl
        id
        data {
          title
          description
          iconColor
          img
          text
          image
          link
        }
        reverse
        title
        use_dark
        shapes
        use_different_header
        other_section_classes {
          remove_padding_top
          remove_padding_bot
          extra_padding_bot
        }
        testimonial {
          title
          author
          text
          bg_decoration
        }
        use_test
        use_custom_footer
      }

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default FeaturesPage
